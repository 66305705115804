import React from "react";
import styled from "styled-components";

class RouteLoading extends React.Component {
    render() {
        return (
            <Container>
                <LogoImage
                    src={
                        "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/15-09-2021/steyp-logo.svg"
                    }
                />
            </Container>
        );
    }
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
`;
const LogoImage = styled.img`
    display: block;
    width: 150px;
    @media (max-width: 980px) {
        width: 100px;
    }
`;

export default RouteLoading;
