import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
  is_logged_in: false,
  // is_menu_active: false,
  // is_student_menu_active: false,
  // is_student_menu_shrink: false,
  // is_student_menu_responsive_shrink: false,
  // is_menu_responsive_active: false,
  main_menu_type: "hidden",
  inner_menu_type: "hidden",
  main_menu_ref: null,
  inner_menu_ref: null,
  is_practice_accepted: false,
  is_support_accepted: false,
  is_modal_enabled: false,
  error: null,
  roles: ["relationship_manager", "examiner"],
  first_menu_active: "dashboard",
  active_students_menu: "",
  user_data: {},
  messages: [],
  chat_profile: "",
  pa_engineer_chat_profile: "",
  menu_router: "",
  router_type: "",
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  const checkRole = (role) => {
    return state.roles.includes(role);
  };

  return (
    <Context.Provider value={{ state, dispatch, checkRole }}>
      {children}
    </Context.Provider>
  );
};

export const Context = createContext(initialState);

export default Store;
