import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Switch } from "react-router-dom";

import RouteLoading from "../loadings/RouteLoading";
import componentLoader from "../componentLoader";
import { PrivateRoute } from "../routes/PrivateRoute";
import { Context } from "../../../Store";
import { AuthRoute } from "../routes/AuthRoute";
import SupportEngineerStore from "../../contexts/stores/SupportEngineerStore";

const AppRouter = lazy(() => componentLoader(() => import("./AppRouter")));
const AuthRouter = lazy(() =>
    componentLoader(() => import("./authentication/AuthRouter"))
);

export default function MainRouter() {
    const { dispatch } = useContext(Context);
    const [isLoading, setLoading] = useState(true);

    async function fetchUserData() {
        let promise = new Promise((resolve, reject) => {
            let user_data = localStorage.getItem("user_data");
            if (!user_data) {
                localStorage.setItem("user_data", JSON.stringify(user_data));
                user_data = localStorage.getItem("user_data");
            }
            let user_data_stored = JSON.parse(user_data);
            dispatch({ type: "UPDATE_USER_DATA", user_data: user_data_stored });
            setTimeout(() => {
                resolve("done!");
                setLoading(false);
            }, 500);
        });

        let result = await promise;
    }

    useEffect(() => {
        fetchUserData();
    }, []);
    return isLoading ? null : (
        <SupportEngineerStore>
            <Suspense fallback={<RouteLoading />}>
                <Switch>
                    <AuthRoute path="/auth/" component={AuthRouter} />
                    <PrivateRoute path="/" component={AppRouter} />
                </Switch>
            </Suspense>
        </SupportEngineerStore>
    );
}
