import React, { createContext, useReducer } from "react";
import SupportEngineerReducer from "../reducers/SupportEngineerReducer";

const initialState = {
    modal_type: "",
    is_modal: false,
    premium_assist_bids: [],
    selected_bid: "",
    active_chat_session: "",
    active_chat_session_id: "",
    active_premium_assist: {},
    previous_pa_sessions: [],
};

const SupportEngineerStore = ({ children }) => {
    const [supportEngineerState, supportEngineerDispatch] = useReducer(
        SupportEngineerReducer,
        initialState
    );

    return (
        <SupportEngineerContext.Provider
            value={{ supportEngineerState, supportEngineerDispatch }}
        >
            {children}
        </SupportEngineerContext.Provider>
    );
};

export const SupportEngineerContext = createContext(initialState);

export default SupportEngineerStore;
